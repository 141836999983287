import moment from 'moment-timezone';

function format12HourTo24Hour(time12h) {
  return moment(time12h, 'h:mm a').format('HH:mm');
}

function format24HourTo12Hour(time24h) {
  return moment(time24h, 'HH:mm').format('h:mm a');
}

function isValidTimeRange({ from, to }) {
  return (from && to) || (!to && !from);
}

export { format12HourTo24Hour, format24HourTo12Hour, isValidTimeRange };
