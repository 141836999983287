export default (parent, dependents) => {
  const json = {
    parent: {
      first_name: parent.firstName,
      last_name: parent.lastName,
      email: parent.email,
    },
  };

  const childJson = { child: {} };
  if (dependents.length > 0) {
    const child = dependents[0];
    childJson.child.first_name = child.firstName;
    childJson.child.last_name = child.lastName;
    childJson.child.birth_date = new Date(child.dateOfBirth).toISOString();
  }

  return btoa(JSON.stringify({ ...json, ...childJson }));
};
